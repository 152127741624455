.icon {
    display: block;
    background: #fff;
    -webkit-mask-repeat: no-repeat !important;
    -moz-mask-repeat: no-repeat !important;
    -ms-mask-repeat: no-repeat !important;
    -o-mask-repeat: no-repeat !important;
    mask-repeat: no-repeat !important;
}

.icon.question {
    -webkit-mask: url("icons/circle-question-regular.svg");
    mask: url("icons/circle-question-regular.svg");
    width: 22px;
    height: 22px;
    background: white;
}
.icon.globe {
    -webkit-mask: url("icons/globe-light.svg");
    mask: url("icons/globe-light.svg");
    width: 22px;
    height: 22px;
    background: white;
}
.icon.user {
    -webkit-mask: url("icons/circle-user-light.svg");
    mask: url("icons/circle-user-light.svg");
    width: 22px;
    height: 22px;
    background: white;
}