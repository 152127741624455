@import url(https://digitalassets.tesla.com/tesla-design-system/raw/upload/static/fonts/intl-fonts/Noto-Sans-KR.css);
* {
    font-family: 'Noto Sans KR', sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #0d0d0d;
}

input {
    padding: 10px;
    border-radius: 5px;
}
button {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.fadeout {
    opacity: 0;
    animation: fade 0.5s forwards;
}
.fadeout2 {
    opacity: 0;
    animation: fade 1.0s forwards;
}
@keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.noselect {
    user-select: none; /* Стандартное свойство */
    -webkit-user-select: none; /* Chrome, Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Старые версии IE */
}


.index-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-size: cover;
    background-position: center;
    justify-content: center;
    color: white;
    text-align: center;
}
.index-content-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.index-content-video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5) 80%,
        #0a0a0a 100%
    );
}
.index-content-video2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    object-fit: cover;
}
.index-content-video2-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.9) 90%,
        #0d0d0d 100%
    );
}
.index-content-button {
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.5s all ease;
    background: #ffffff1f;
    text-decoration: none;
    color: #ffffffbf;
}
.index-content-button:hover {
    transition: 0.5s all ease;
    background: #ffffff0f;
}
.index-content-header {
    margin-top: 5vh;
    height: 100px;
    z-index: 1;
}
.index-content-footer {
    margin-top: 60vh;
    height: 100px;
    z-index: 1;
}


.header-main {
    position: absolute;
    height: 55px;
    width: 100%;
    align-content: center;
    z-index: 4;
    color: white;
}
.header-container {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 0px;
}
.header-container.left {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.header-container.right {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.header-button {
    border-radius: 5px;
    cursor: pointer;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    transition: 0.5s all ease;
    background: #ffffff00;
    color: white;
    text-decoration: none;
}
.header-button.active {
    transition: 0.5s all ease;
    background: #ffffff2f;
}
.header-button.icon {
    padding: 5px;
    margin-right: 5px;
    background: #00000000;
}
.header-button.icon:hover {
    transition: 0.5s all ease;
    background: #ffffff2f;
}


.header-page {
    transition: 0.5s all ease;
    position: absolute;
    overflow: hidden;
    background: #0d0d0d;
    width: 100%;
    max-height: 0px;
    opacity: 0.0;
    transform: translateY(-50px);
    z-index: 3;
}
.header-page.active {
    max-height: 100vh;
    opacity: 1.0;
    transform: translateY(0px);
}

.header-page-end {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.header-page-end.active {
    display: block;
}
.header-page-title {
    color: #ffffff4d;
    margin-top: 7vh;
    text-align: center;
    display: flex;
    justify-content: center;
}
.header-page-container {
    margin-top: 5vh;
    text-align: center;
    display: flex;
    justify-content: center;
}
.header-page-container2 {
    text-align: center;
    display: flex;
    justify-content: center;
}


.header-container-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 150px;
    height: 160px;
    padding: 5px;
    transition: 0.5s all ease;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.header-container-item:hover {
    transition: 0.5s all ease;
    background: #ffffff0f;
}

.header-container-item2 {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: auto;
    height: auto;
    padding: 5px;
    transition: 0.5s all ease;
    margin: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    text-decoration: none;
}
.header-container-item2 .title {
    color: #ffffff4d;
}
.header-container-item2 .link {
    cursor: pointer;
    transition: 0.5s all ease;
    color: #ffffff9f;
    border-bottom: 1px solid #ffffff00;
    margin: 5px;
}
.header-container-item2 .link:hover {
    transition: 0.5s all ease;
    color: #fff;
    border-bottom: 1px solid #fff;
}

.header-container-item2 .button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 170px;
    height: 50px;
    padding: 5px;
    padding-left: 15px;
    transition: 0.5s all ease;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: left;
    color: white;
    text-decoration: none;
}
.header-container-item2 .button:hover {
    transition: 0.5s all ease;
    background: #ffffff0f;
}
.header-container-item2 .button .desc {
    margin: 0px;
    color: #ffffff4d;
    font-size: 14px;
}

.header-container-item2 .button-inactive {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 170px;
    height: 50px;
    padding: 5px;
    padding-left: 15px;
    transition: 0.5s all ease;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: left;
    color: #ffffff2d;
    text-decoration: none;
}
.header-container-item2 .button-inactive:hover {
    transition: 0.5s all ease;
    background: #ffffff04;
}
.header-container-item2 .button-inactive .desc {
    margin: 0px;
    color: #ffffff2d;
    font-size: 14px;
}


.category-content {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
}
.category-content .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 300px;
    height: 240px;
    --padding: 5px;
    transition: 0.5s all ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    background: #0000000f;
}
.category-content .item:hover {
    transition: 0.5s all ease;
    background: #ffffff09;
}
.category-content .item img {
    transition: 0.5s ease all;
    width: 110%;
    margin-top: 0%;
}
.category-content .item img:hover {
    transition: 0.5s ease all;
    width: 120%;
    margin-top: -5%;
}
.category-content .item .text {
    color: white;
}
.category-content .item .text_left {
    color: white;
    font-size: 16px;
    margin-right: auto;
    padding-left: 10px;
}
.category-content .item .text_right {
    color: white;
    color: #49add5ff;
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    padding-right: 10px;
}
.category-content .item .title {
    width: 100%;
    display: flex;
    flex-direction: row;
}


.footer {
    background: #0a0a0a;
    color: white;
    text-align: center;
}