.fadeout {
    opacity: 0;
    animation: fade 0.5s forwards;
}
@keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.loading {
    background: linear-gradient(100deg, #6382f51a 8%, #6382f52a 18%, #6382f51a 33%); 
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    color: #00000000;
}
@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.hide {
    display: none !important;
}

.clickable {
    cursor:  pointer;
}

.text {
    white-space: pre-wrap; /* or pre-line */
}


body .notification-wrapper-block {
    position: fixed;
    top: 10%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0%);
    z-index: 10000;
    display: flex;
    flex-direction: column;
    pointer-events: none;
}

body .notification-wrapper-block .notification-block {
    background: #1a1a22;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    z-index: 1000;
    display: flex;
    align-items: center;
    min-height: 50px;
    max-width: 600px;
    border-radius: 5px;
    padding-right: 15px;
    opacity: 0;
    transition: .25s all;
    margin: 0 auto;
    margin-bottom: 10px;
}
body.light .notification-wrapper-block .notification-block {
    background: #fff;
}

body .notification-wrapper-block .notification-block.show {
    opacity: 1;
}

body .notification-wrapper-block .notification-block:last-child {
    margin-bottom: 0;
}

body .notification-wrapper-block .notification-block .icon-note {
    height: 60px;
    width: 60px;
    min-width: 60px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

body .notification-wrapper-block .notification-block .icon-note .icon {
    background: #fff;
    height: 25px;
    width: 25px;
}

body .notification-wrapper-block .notification-block.warning .icon-note,
body .notification-wrapper-block .notification-block.bigger.warning .header-note {
    background: #EA384D;
}

body .notification-wrapper-block .notification-block.warning .icon-note .icon {
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95 c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241 c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30 V317.241z' fill='%23ffffff' data-original='%23000000' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95 c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241 c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30 V317.241z' fill='%23ffffff' data-original='%23000000' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

body .notification-wrapper-block .notification-block.info .icon-note,
body .notification-wrapper-block .notification-block.bigger.info .header-note {
    background: #6382F5;
}

body .notification-wrapper-block .notification-block.info .icon-note .icon {
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M256,0C114.509,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.491,0,256-114.496,256-256 C512,114.511,397.504,0,256,0z M282.289,357.621c0,8.088-11.794,16.174-26.284,16.174c-15.164,0-25.946-8.086-25.946-16.174 V229.234c0-9.435,10.783-15.839,25.946-15.839c14.49,0,26.284,6.404,26.284,15.839V357.621z M256.006,182.396 c-15.501,0-27.631-11.457-27.631-24.263c0-12.805,12.131-23.925,27.631-23.925c15.164,0,27.296,11.12,27.296,23.925 C283.302,170.939,271.169,182.396,256.006,182.396z' fill='%23ffffff' data-original='%23000000'/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M256,0C114.509,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.491,0,256-114.496,256-256 C512,114.511,397.504,0,256,0z M282.289,357.621c0,8.088-11.794,16.174-26.284,16.174c-15.164,0-25.946-8.086-25.946-16.174 V229.234c0-9.435,10.783-15.839,25.946-15.839c14.49,0,26.284,6.404,26.284,15.839V357.621z M256.006,182.396 c-15.501,0-27.631-11.457-27.631-24.263c0-12.805,12.131-23.925,27.631-23.925c15.164,0,27.296,11.12,27.296,23.925 C283.302,170.939,271.169,182.396,256.006,182.396z' fill='%23ffffff' data-original='%23000000'/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

body .notification-wrapper-block .notification-block.success .icon-note,
body .notification-wrapper-block .notification-block.bigger.success .header-note {
    background: #3DB27A;
}

body .notification-wrapper-block .notification-block.success .icon-note .icon {
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 408.576 408.576' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288 S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6 c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344 c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z' fill='%23ffffff' data-original='%23000000' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='25' height='25' x='0' y='0' viewBox='0 0 408.576 408.576' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288 S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6 c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344 c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z' fill='%23ffffff' data-original='%23000000' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

body .notification-wrapper-block .notification-block p {
    font-weight: 600;
    color: #ffffffdd;
}

body .notification-wrapper-block .notification-block.bigger {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
}

body .notification-wrapper-block .notification-block.bigger .header-note {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
}

body .notification-wrapper-block .notification-block.bigger .header-note .icon-note {
    width: auto;
    min-width: auto;
}

body .notification-wrapper-block .notification-block.bigger p {
    padding: 10px;
}



.main_header_bg {
    height: 80px;
}
.main_header {
    position: fixed;
    z-index: 666;
    top: 0;
    left: 0;
}

.dynamic {
    transition: 0.3s ease all;
}
.dynamic:hover {
    transition: 0.5s ease all;
    transform: translate(0%, -5%);
}


.close-button {
    position: absolute;
    right: 0;
}


.custom-input {
    background: #edecf896;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    font-weight: 600;
    min-height: 60px;
    color: #444;
    cursor: pointer;
}

.feed_language {
    margin-right: 5px;
    background: #eee;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
.feed_language.active {
    background: #6382F5;
    color: #fff;
}

.footer_language_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.footer_language {
    margin: 0;
    background: #000;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
.footer_language.active {
    background: #6382F599;
    font-weight: bold;
    color: #fff;
}

.like_active {
    color: #fb0b0b !important;
    background: #f1caca !important;
}
.like_active .icon {
    background: #fb0b0b !important;
}

.video-custom {
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
}

@media screen and (max-width: 600px) {
    .modal {
        min-width: 100px;
    }
    .button_fix_1 {
        --width: 100px;
    }
    .button_fix_2 {
        --width: auto;
    }
    .button_fix_3 {
        --width: 150px;
    }
    .button_fix_4 {
        --width: 100px;
    }
}